// import Vue from 'vue'
import DynamicPage from '@xpedeo/core/dist/DynamicPage'
// import { o } from '@xpedeo/core/dist/index2'
import XpPreloadModal from './XpPreloadModal.vue'
export default DynamicPage.extend({
  components: {
    XpPreloadModal
  },
  methods: {
    renderPreloadModal (h) {
      // return (this.$xp.content.showPreloadModal && (this.$xp.device.platform === 'ios' || (this.$xp.device.platform === 'android' && !this.$xp.device.isLoan)))
      //   ? h(XpPreloadModal, {
      //     props: {
      //       preloadPath: this.$xp.content.preloadPath
      //     }
      //   })
      //   : null
      return this.$xp.content.showPreloadModal ? h(XpPreloadModal) : null
      // , {
      // props: {
      //   preloadPath: this.$xp.content.preloadPath
      // }
      // })
      // : null
    }
  }
})
